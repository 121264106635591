<template>
    <div class="login-container">
        <div class="logo">
            <img class="logo-img" src="@/assets/login/logo.png">
        </div>
        <div class='login-tip'>
            欢迎使用<br/>
            一体化智能商品<span>分拣系统</span>。
        </div>
        <div class="login-form-container">
            <div class="login-view">
                <a-form-model ref="loginForm" style="width: 386px" :model="form" :rules="formRules">
                    <a-form-model-item lable="用户名/邮箱、手机号" prop="name">
                        <a-input placeholder="用户名/邮箱、手机号" v-model="form.name" class="form-item"/>
                    </a-form-model-item>

                    <a-form-model-item lable="密码" prop="password" style="margin-top: 30px">
                        <a-input placeholder="密码" v-model="form.password" class="form-item" type="password"/>
                    </a-form-model-item>
                    <a-form-model-item style="display: flex; align-items: center; justify-content: flex-end">
                        <div class="get-psw-view" @click="isRemeberPsw = !isRemeberPsw">
                            <img v-if="isRemeberPsw" src="@/assets/login/get_psw.png">
                            <div v-else class="get-psw-no-choise"/>
                            <span>记住密码</span>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button class="login-btn" size="large" type="primary" @click="onSubmit" :loading="btnloading">
                            登录
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations
    } from 'vuex'
  export default {
    name: "login",
    data() {
      return {
        formRules: {
          name: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
        },
        form: {
          name: 'zyt',
          password: 'ls123.'
        },
        btnloading:false,
        isRemeberPsw: true
      }
    },
    mounted(){
        console.log(localStorage.getItem('access_token'))
        this.form.name = localStorage.getItem('name')
        this.form.password = localStorage.getItem('password')
    },
    methods: {
      ...mapMutations(['setMember', 'setToken']),
      onSubmit() {
          const name = this.form
          const password = this.form
        this.$refs.loginForm.validate(valid => {
          if (valid) {
              this.btnloading=true;
              this.rq.post('/merapi/sorting/o-auth/login',{username:this.form.name,password:this.form.password,group:'sorting_weigh'}).then(res=>{
                if (this.isRemeberPsw) {
                  localStorage.setItem('name', this.form.name)
                  localStorage.setItem('password', this.form.password)
                } else {
                  localStorage.removeItem('name')
                  localStorage.removeItem('password')
                }
                  this.setMember(res.data.member)
                  this.setToken(res.data.access_token)
                  this.btnloading=false;
                  this.$router.push('/')
              }).catch(err=>{
                   this.btnloading=false;
              })
          } else {
            this.btnloading=false;
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped lang="less">
    .login-container {

        background-color: #fff;
        background-image: url("../assets/login/bg.png");
        background-position: left bottom;
        background-size: 50% auto;
        background-repeat: no-repeat;
        height: 800px;
        width: 100%;
        .logo {
            width: 202px;
            position: fixed;
            left: 70px;
            top: 58px;
            .logo-img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .login-tip {
            position: fixed;
            left: 162px;
            top: 185px;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            letter-spacing: 2px;
            &>span {
                color: #0427AF
            }
        }
        .login-form-container {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            left: 54.69%;
            display: flex;
            align-items: center;
            justify-content: center;
            .login-view {
                width: 488px;
                height: 430px;
                background: #FFFFFF;
                box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                .form-item {
                    height: 70px;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    letter-spacing: 1px;
                }
                .login-btn {
                    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                    border-radius: 4px;
                    border: 1px solid rgba(32, 53, 128, 0.16);
                    height: 70px;
                    width: 100%
                }
                .get-psw-view {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &>img {
                        width: 22px;
                        height: 22px;
                    }
                    &>div {
                        width: 22px;
                        height: 22px;
                        border-radius: 22px;
                        border: 1px solid gray;
                    }
                    &>span {
                        margin-left: 5px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #080808;
                        line-height: 25px;
                        letter-spacing: 1px;
                    }
                }

            }
        }
    }
</style>